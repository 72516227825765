import {
    Create,
    Box,
    TextField
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

interface IVisitor {
    email: string;
    title: string;
    full_name: string;
    company: string;
    position: string;
    ticket_type: "Visitor" | "Exibitor" | "Press" | "VIP";
}

export const VisitorCreate: React.FC = () => {
    const { saveButtonProps, register } = useForm<IVisitor>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("email", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Email" name="email" />
                <TextField
                    {...register("title", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Title" name="title" />
                <TextField
                    {...register("full_name", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Full Name" name="full_name" />
                <TextField
                    {...register("company", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Company" name="company" />
                <TextField
                    {...register("position", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Position" name="position" />
                <TextField
                    {...register("ticket_type", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Ticket Type" name="ticket_type" />
            </Box>
        </Create>
    );
};
