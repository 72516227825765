import { Refine, AuthProvider, AuthPage } from "@pankod/refine-core";
import {
    Layout,
    ErrorComponent,
    ReadyPage,
    LightTheme,
    ThemeProvider,
    notificationProvider,
    RefineSnackbarProvider,
    CssBaseline,
    GlobalStyles,
} from "@pankod/refine-mui";
import AirplayIcon from '@mui/icons-material/Airplay';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";

import { VisitorCreate, VisitorEdit, VisitorList } from "pages/visitors";
import { QuestionList } from "pages/questions";
import { Dashboard } from "pages/dashboard";

const App: React.FC = () => {

    const authProvider: AuthProvider = {
        login: async ({ email, password }) => {
            if (email === "admin@asit.rs" && password === "admin") {
                localStorage.setItem("email", email);
                return Promise.resolve();
            }
            return Promise.reject();
        },
        logout: () => { localStorage.removeItem("email"); return Promise.resolve(); },
        checkAuth: () => { return localStorage.getItem("email") ? Promise.resolve() : Promise.reject() },
        checkError: () => { return Promise.resolve(); },
        getPermissions: () => Promise.resolve(["admin"]),
        getUserIdentity: () =>
            Promise.resolve({
                id: 1,
                name: "Admin",
                avatar: "./logo.png",
            }),
    };

    return (
        <ThemeProvider theme={LightTheme}>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
                <Refine
                    routerProvider={routerProvider}
                    dataProvider={dataProvider("https://technobank.app/api/v1.0")}
                    notificationProvider={notificationProvider}
                    authProvider={authProvider}
                    ReadyPage={ReadyPage}
                    Layout={Layout}
                    LoginPage={() => <AuthPage
                        rememberMe=""
                        registerLink=""
                        forgotPasswordLink=""
                        type="login"
                        wrapperProps={{
                            style: {
                                color: "white",
                                background: "#1C131C",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px"
                            },
                        }}
                        contentProps={{
                            title: "aQReditivo",
                            style: {
                                width: 512,
                                background: "#1C131C"
                            },
                        }}
                        renderContent={(content: React.ReactNode) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {content}
                                </div>
                            );
                        }}
                    />}
                    catchAll={<ErrorComponent />}
                    Title={({ collapsed }) => (
                        <div>
                            {collapsed && <img height={50} src="./logo.png" alt="Logo" />}
                            {!collapsed && <h1 style={{ color: "white" }}>aQReditivo</h1>}
                        </div>
                    )}
                    resources={[
                        {
                            name: "dashboard",
                            list: Dashboard,
                            icon: <AirplayIcon />,
                            options: {
                                route: "dashboard",
                                label: "Dashboard"
                            }
                        },
                        {
                            name: "visitors",
                            list: VisitorList,
                            create: VisitorCreate,
                            edit: VisitorEdit,
                            icon: <SupervisorAccountIcon />
                        }, {
                            name: "questions",
                            list: QuestionList,
                            icon: <QuestionMarkIcon />
                        }
                    ]}
                />
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
