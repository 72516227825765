import React, { useState, useEffect } from "react";
import { useOne, HttpError } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Button
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import csvDownload from 'json-to-csv-export'

interface IDashboard {
  name: string,
  address: string,
  date_from: string,
  date_to: string
}

export const Dashboard: React.FC = () => {
  // get details of the dashboard
  const [id] = useState("");
  const { data, isLoading, isError } = useOne<IDashboard, HttpError>({ resource: "dashboard", id });
  const dashboard = data?.data ?? { name: "", address: "", date_from: "", date_to: "" };
  // display loading until data are fetched or error ,essage
  useEffect(() => {
    const displayLoadingOrError = () => {
      const message = isLoading ? "Loading.." : (isError ? "Something went wrong!" : null)
      if (message) { return <div>{message}</div>; }
    }
    displayLoadingOrError();
  })
  // define handle send emails button click
  const handleSendEmails = () => {
    fetch("https://technobank.app/api/v1.0/emails/send")
  }
  // define handle report visitors button click
  const handleReport = () => {
    const url = "https://technobank.app/api/v1.0/emails/report"
    fetch(url)
      .then(res => res.json())
      .then(data => {
        const dataToConvert = {
          data: data,
          filename: 'report',
          delimiter: ',',
          headers: ['To', 'ReceivedAt']
        }
        csvDownload(dataToConvert)
      });
  }
  // define handle export visitors button click
  const handleExport = () => {
    const url = "https://technobank.app/api/v1.0/export/visitors"
    fetch(url)
    .then(res => res.json())
    .then(data => {
      const dataToConvert = {
        data: data,
        filename: 'visitors',
        delimiter: ',',
        headers: ['email', 'title', 'full_name', 'company', 'position', 'ticket_type','id','is_qr_email_sent']
      }
      csvDownload(dataToConvert)
    });
  }
  // display daashboard detail in the editable form
  const { saveButtonProps, register } = useForm<IDashboard>();
  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="Conference"
      goBack={null}
      headerButtons={[
        <Button onClick={handleSendEmails} color="error">
          Send Emails with QR Code to all Visitors
        </Button>,
        <Button onClick={handleReport} color="warning">
          Report of sent emails
        </Button>,
        <Button onClick={handleExport} color="info">
          Export all Visitors as CSV file
        </Button>,
      ]}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField key="dashboard.name" {...register("name", {
          required: "required",
        })} InputLabelProps={{ shrink: true }} margin="normal" label="Name" name="name" />
        <TextField key="dashboard.address" {...register("address", {
          required: "required",
        })} InputLabelProps={{ shrink: true }} margin="normal" label="Address" name="address" />
        <TextField key="dashboard.date_from" {...register("date_from", {
          required: "required",
        })} InputLabelProps={{ shrink: true }} margin="normal" label="From" name="date_from" />
        <TextField key="dashboard.date_to" {...register("date_to", {
          required: "required",
        })} InputLabelProps={{ shrink: true }} margin="normal" label="To" name="date_to" />
      </Box>
    </Edit>
  );
};
