import {
    Edit,
    Box,
    TextField
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

interface IPrintData {
    company: string;
    full_name: string;
    position: string;
    ticket_type: string;
}

export const VisitorEdit: React.FC = () => {

    const { saveButtonProps, register } = useForm<IPrintData>();

    return (
        <Edit saveButtonProps={saveButtonProps} title={<h3>Edit visitor data for Print</h3>}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("company", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Company" name="company" />
                <TextField
                    {...register("full_name", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Full Name" name="full_name" />
                <TextField
                    {...register("position", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Position" name="position" />
                <TextField
                    {...register("ticket_type", {
                        required: "required",
                    })}
                    InputLabelProps={{ shrink: true }}
                    margin="normal" label="Ticket Type" name="ticket_type" />
            </Box>
        </Edit>
    );
};
