import React, { useState } from "react";
import { useList, HttpError } from "@pankod/refine-core";
import {
    DataGrid,
    GridColumns,
    List,
    Button,
    EditButton,
    CreateButton,
} from "@pankod/refine-mui";
import { FileUploader } from "react-drag-drop-files";

interface IVisitor {
    id: number,
    email: string;
    title: string;
    full_name: string;
    company: string;
    position: string;
}

export const VisitorList: React.FC = () => {
    const [file, setFile] = useState<File>();

    const columns = React.useMemo<GridColumns<IVisitor>>(
        () => [
            { field: "id", headerName: "ID", type: "number", width: 50 },
            { field: "title", headerName: "Title", minWidth: 50 },
            { field: "email", headerName: "Email", minWidth: 120, flex: 0.3 },
            { field: "full_name", headerName: "Full Name", minWidth: 120, flex: 0.3 },
            { field: "company", headerName: "Company", minWidth: 120, flex: 0.3 },
            { field: "position", headerName: "Position", minWidth: 120, flex: 0.3 },
            { field: "ticket_type", headerName: "Ticket Type", minWidth: 120, flex: 0.3 },
            {
                field: "actions",
                headerName: "Actions",
                renderCell: function render({ row }) {
                    return <React.Fragment>
                        <EditButton hideText recordItemId={row.id} />
                        <Button color="error" onClick={() => sendToPrinter(row.email)} >Print</Button>
                    </React.Fragment>;
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    const sendToPrinter = (email: string) => {
        fetch(`https://technobank.app/api/v1.0/visitors/${email}/print`)
    }
    
    const uploadFile = () => {
        if (!file) {
            return
        }
        let data = new FormData()
        data.append("file", file)
        fetch("https://technobank.app/api/v1.0/visitors/upload", {
            method: "POST",
            body: data,
            headers: {
                "Accept": "application/json"
            }
        }).then(res => res.json())
            .then(data => location.reload())
    }

    const { data, isLoading, isError } = useList<IVisitor, HttpError>({
        resource: "visitors",
    });
    const visitors = data?.data ?? [];
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <List
            headerButtons={[
                <div>
                    {file?.name ?
                        (
                            <div >
                                <span>{file?.name}</span>
                                <Button color="error" onClick={uploadFile}>Upload</Button>
                            </div>
                        )
                        : <FileUploader
                            name="file"
                            label="Upload visitors file"
                            types={["CSV"]}
                            handleChange={(file: File) => setFile(file)} />
                    }
                </div>,
                <CreateButton />
            ]
            }
        >
            <DataGrid rows={visitors} columns={columns} autoHeight />
        </List>
    );
};
