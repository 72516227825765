import React, { useState } from "react";
import { useList, HttpError } from "@pankod/refine-core";
import {
    DataGrid,
    GridColumns,
    List,
    Button
} from "@pankod/refine-mui";
import { FileUploader } from "react-drag-drop-files";

interface IQuestion {
    id: number,
    daySelector: string,
    email: string,
    topic: string,
    question: string,
}

export const QuestionList: React.FC = () => {
    const [file, setFile] = useState<File>();

    const columns = React.useMemo<GridColumns<IQuestion>>(
        () => [
            { field: "id", headerName: "ID", type: "number", width: 20, },
            { field: "email", headerName: "Email", minWidth: 80, flex: 0.2},
            { field: "daySelector", headerName: "Day", minWidth: 80, flex: 0.2},
            { field: "topic", headerName: "Topic", minWidth: 80, flex: 0.3 },
            { field: "question", headerName: "Question", minWidth: 120, flex: 0.4 }
        ],
        [],
    );
    const uploadFile = () => {
        if (!file) {
            return
        }
        let data = new FormData()
        data.append("file", file)
        fetch("https://technobank.app/api/v1.0/topics/upload", {
            method: "POST",
            body: data,
            headers: {
                "Accept": "application/json"
            }
        }).then(res => res.json())
            .then(data => location.reload())
    }

    const { data, isLoading, isError } = useList<IQuestion, HttpError>({
        resource: "questions",
    });
    const questions = data?.data ?? [];
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <List
            headerButtons={[
                <div>
                    {file?.name ?
                        (
                            <div>
                                <span>{file?.name}</span>
                                <Button color="error" onClick={uploadFile}>Upload</Button>
                            </div>
                        )
                        : <FileUploader
                            name="file"
                            label="Upload topics"
                            types={["CSV"]}
                            handleChange={(file: File) => setFile(file)} />
                    }
                </div>
            ]
            }
        >
            <DataGrid rows={questions} columns={columns} autoHeight />
        </List>
    );
};
